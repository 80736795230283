import React from "react";
import NavBar2 from "../NavBar";
import Footer from "../Footer";
import car from "../../../assets/car.svg";
import shield from "../../../assets/shield-search.svg";
import shieldWhite from "../../../assets/shield-white.svg";
import "../style.css";
import bolttext from "../../../newassets/bolttext.svg";
import { FloatingWhatapp } from "../FloatingWhatapp";

const BoltPage = () => {
  return (
    <div>
      <NavBar2 isHomePage={false} isBoltPage={false} />

      <div className="position-relative bg-container">
        <div className="position-absolute top-50 start-50 translate-middle text-center d-flex flex-column align-items-center img-parent">
          <img src={bolttext} alt="Bolt" className="img-fluid mb-2" />
          <span className="d-block custom-header">Rider Verification</span>
        </div>
      </div>

      <div className="tw-px-[.7rem] tw-py-[2rem] md:tw-max-w-[797px] mx-auto md:tw-my-[2rem]">
        <p className="tw-font-epi tw-font-[600] tw-text-[2.5rem] tw-leading-[48px] text-center md:tw-text-[3.875rem] md:tw-leading-[68px]">
          What are you applying for?
        </p>
        <p className="tw-text-[#353535] tw-text-center tw-font-generalSansMedium tw-font-[500] tw-text-[1.125rem] md:tw-max-w-[500px] mx-auto md:tw-text-[1.5rem]">
          Choose your preferred check by clicking a box below
        </p>
      </div>

      <div className="tw-flex tw-flex-col tw-gap-[1rem] md:tw-flex-row md:tw-max-w-[800px] mx-auto">
        <Pane
          title={"DL Check"}
          body={"Verify your Driver's License."}
          img={car}
          // link="https://forms.prembly.com/view/d763324a-6c56-4bf8-93bd-0139391851b4"
          link="https://bgc.prembly.com/BackgroundCheck/Requests/Accept?packageRef=d6741437-df4f-4e39-be34-2310a7a56322"

          newTab={true} // Opens in a new tab
        />
        <Pane
          title={"Criminal Check"}
          body={"Run Criminal Records Check."}
          img={shield}
          link="https://forms.prembly.com/view/6d30bc91-90fd-4528-a6d1-21974e79d971"
          // link="https://bgc.prembly.com/BackgroundCheck/Requests/Accept?packageRef=8f20da67-2f24-45fa-b233-80e1cabc6600"
          newTab={true} // Opens in a new tab
        />
        <Pane
          title={"Tracking"}
          body={"Track your Application"}
          img={shieldWhite}
          link="/tracking"
          newTab={false}
          className="aa"
        />
      </div>

      <div className="text-center mx-auto py-4 mergerText">
        <h3>
          <span>*Peleza is now Prembly Group.</span>{" "}
          <a
            href="https://blog.prembly.com/highlights-and-insights-on-the-merger-peleza-international-and-prembly-ltd-as-prembly-group/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more about our merger
          </a>
        </h3>
      </div>
      <FloatingWhatapp />
      <Footer />
    </div>
  );
};

export default BoltPage;

const Pane = ({ title, body, img, link, newTab, className }) => {
  return (
    <a
      href={link}
      className={`block tw-text-[#0B0A0A] tw-w-[280px] tw-text-center tw-py-[44px] tw-rounded-[1rem] mx-auto ${className || "tw-bg-[#F4FBFA]"
        }`}
      {...(newTab && { target: "_blank", rel: "noopener noreferrer" })}
    >
      <img src={img} alt="" className="tw-block pb-[1rem] mx-auto" />
      <p className="tw-font-[600] tw-font-generalSansSemiBold text-[1.125rem]">
        {title}
      </p>
      <p className="tw-font-[500] tw-text-[0.875rem] tw-font-generalSansMedium">
        {body}
      </p>
    </a>
  );
};

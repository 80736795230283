import twitter from "../../assets/twitter.svg";
import { Link, useLocation } from "react-router-dom";
import insta from "../../assets/instagram.svg";
import React, { useState } from "react";
import { Button, Modal } from "antd";
import "antd/dist/reset.css";
import Cookies from "js-cookie";
import cookie from "../.././assets/cookie.svg";
import { useEffect } from "react";
import K from "../.././assets/K.png";
import compliance from "../.././assets/complianceQR.png";


export default function Footer() {
  let location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let setAllCookieConsent = () => {
    Cookies.set("allCookieConsent", "accepted");
    handleCancel();
  };

  let setNecessaryCookieConsent = () => {
    Cookies.set("necessaryCookieConsent", "accepted");
    handleCancel();
  };

  useEffect(() => {
    const cookieConsent =
      Cookies.get("allCookieConsent") ||
      Cookies.get("necessaryCookieConsent") ||
      "";
    cookieConsent === "" ? setIsModalOpen(true) : setIsModalOpen(false);
  }, []);

  return (
    <div className="footer-area py-5">
      <div className="container">
        <div className="col-md-12 mx-auto">
          <div className="row footer-contents" id="footer-content-row">
            <div className=" col-md-3 col-lg-2 mt-5">
              <Link Link to="/" className="link">
                <img
                  src="https://prembly-assets.s3.us-east-2.amazonaws.com/footerLogo.png"
                  alt=""
                  width="120px"
                />
              </Link>
              <div className=" mt-4 place">
                <p>Washington, DC</p>
              </div>

              <div className=" mt-4 place">
                <p>Lagos, Nigeria</p>
              </div>
              <div className=" mt-4 col-md-12 col-lg-12">
                <h4 className="">Follow Us</h4>
                <p className=" socials mt-4">
                  {/* {(splitLocation[1] === "" || splitLocation[1] === "Policy") && */}
                  <>
                    <a
                      href="https://web.facebook.com/Premblydotmind"
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/face.svg"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://twitter.com/prembly"
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitter} alt="" />
                    </a>
                    <a
                      href="https://www.instagram.com/premblyhq/"
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={insta} alt="" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/prembly/"
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="https://prembly-assets.s3.us-east-2.amazonaws.com/ins.svg"
                        alt=""
                      />
                    </a>
                  </>
                </p>
              </div>
            </div>

            <div className=" col-md-3 col-lg-2 mt-5 ">
              <h4>Products</h4>
              <div className="mt-4">
                <Link to="/identityPass" className="link">
                  <p className="">Identitypass</p>
                </Link>
              </div>
              <a href="https://identityradar.prembly.com/" className="link">
                <p className="">Identityradar</p>
              </a>
              <Link to="/backgroundCheck" className="link">
                {" "}
                <p>Background Checks</p>{" "}
              </Link>
              {/* <Link to="identityForm" className="link">
                {" "}
                <p>IdentityForms</p>{" "}
              </Link> */}
              <a href="https://identityforms.prembly.com/" target="_blank" className="link" rel="noreferrer">
                {" "}
                <p>IdentityForms</p>{" "}
              </a>
            </div>
            {/* } */}

            <div className=" col-md-3 col-lg-2 mt-5 ">
              <h4>Industries</h4>
              <div className=" mt-4">
                <Link to="/sector" className="link">
                  <p>Financial Sector</p>
                </Link>

                <Link to="/crypto" className="link">
                  <p>Cryptocurrency</p>
                </Link>

                <Link to="/management" className="link">
                  <p>HR Management</p>
                </Link>

                <Link to="/insurance" className="link">
                  <p>Insurance</p>
                </Link>

                <Link to="/mobilty" className="link">
                  <p>Mobility</p>
                </Link>

                <Link to="/tobacco" className="link">
                  <p>Tobacco & Alcohol</p>
                </Link>

                <Link to="/publicSector" className="link">
                  <p>Public Sector</p>
                </Link>

                {/* <a href="mailto:team@myidentitypass.com" className="link" target="_blank" rel="noreferrer">
                                    <p>Financial Institution</p>
                                </a> */}
              </div>
            </div>

            <div className=" col-md-3 col-lg-2 mt-5 ">
              <h4>Resources</h4>
              <div className=" mt-4">
                {/* <Link
                  to="/customer"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Customer Testimonial</p>
                </Link> */}

                <a
                  href="https://blog.prembly.com/"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Blog</p>
                </a>

                <Link
                  to="/press"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Press & Media</p>
                </Link>

                <Link
                  to="/faqs"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>FAQs</p>
                </Link>

                <Link
                  to="/event"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Events</p>
                </Link>
              </div>
              {/* <a href="mailto:team@myidentitypass.com" className="link" target="_blank" rel="noreferrer">
                                <p>Contact Us</p>
                            </a> */}
              {/* <a href="#partners" className="link" target="_blank" rel="noreferrer">
                                <p>Partners</p>
                            </a> */}
              {/* <a href="/#partnerSponsor" className="link"> <p>Partner or Sponsor</p> </a> */}
            </div>

            <div className="col-md-3 col-lg-2 mt-5 ">
              <h4>Company</h4>
              <div className="mt-4">
                <Link to="/about" className="link">
                  <p>About Us</p>
                </Link>

                <Link to="/career" className="link">
                  <p>Careers</p>
                </Link>
                
                <Link to="/contact" className="link">
                  <p>Contact Us</p>
                </Link>

                {/* <Link to="/meet" className="link">
                  <p>Meet The Team</p>
                </Link> */}

                {/* <Link to="/compliance" className="link">
                  <p>Compliance Certificates</p>
                </Link> */}

                <Link
                  to="/partnership"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Become a Partner</p>
                </Link>
              </div>
            </div>

            {/* <div className="col-6 col-md-3 col-lg-3 mt-5 ">
                      <h4>Legal</h4>
                      <p className="mt-4">Terms & Conditions</p>
                      <p>About Us</p>
                      d<p>Contact Us</p>
                </div> */}
          </div>

          <div className="row justify-content-center mx-auto align-items-center text-center  ">
            <div className="scanerQRCode">
              <div className="scanCard">
                <img src={K} alt="world" className="worldQR" />
                <Link
                  to="/compliance"
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  <img
                    src={compliance}
                    alt="complianceQRCode"
                    className="QRcode"
                  />
                </Link>
              </div>
              <p className="pt-3">
                We are SOC 2 and NDPR compliant. Scan QR code to visit webpage
              </p>
            </div>
          </div>

          <div
            className=""
            style={{
              borderTop: "1px solid #E3F0EF69",
              borderBottom: "1px solid #E3F0EF69",
              margin: "1rem 0 0 0",
            }}
          >
            <div className="row align-items-center mt-3 footer-contents">
              <div className="col-md-2">
                <a href="/Policy" className="link">
                  <p className="policies-title">Privacy Policy</p>
                </a>
              </div>

              <div className="col-md-2">
                <a href="/terms" className="link">
                  <p className="policies-title">Terms Of Service</p>
                </a>
              </div>

              <div className="col-md-2">
                <a href="/dataProtection" className="link">
                  <p className="policies-title">Data Protection Policy</p>
                </a>
              </div>

              <div className="col-md-2">
                <a
                  className="link"
                  onClick={showModal}
                  style={{ cursor: "pointer" }}
                >
                  <p className="policies-title">Manage Cookie Preference</p>
                </a>
                <>
                  <Modal
                    open={isModalOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <div className="cookie-box">
                      <img src={cookie} alt="cookie" />
                      <p>
                        By clicking “Accept all cookies”, you agree Prembly can
                        store cookies on your device and disclose information in
                        accordance with our{" "}
                        <Link to="/Policy" className="cookie-link">
                          Cookie Policy
                        </Link>
                        .
                      </p>
                      <div className="cookie-btn">
                        <button
                          className="btn btn-green1"
                          onClick={setAllCookieConsent}
                        >
                          Accept all cookies
                        </button>
                        <button
                          href=""
                          className="btn btn-green2 btn-green-outline"
                          onClick={setNecessaryCookieConsent}
                        >
                          Necessary cookies only
                        </button>
                      </div>
                      <button className="btn btn-greennew btn-green-outline">
                        Customize settings
                      </button>
                    </div>
                  </Modal>
                </>
              </div>

              <div className="col-md-3">
                <a className="link">
                  <p className="d-flex align-items-center policies-title">
                    {/* <i className="ri-copyright-line"></i>  */}
                    &#9400; Prembly. Inc. All Rights Reserved. {new Date().getFullYear()}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

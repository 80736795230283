import { Fragment, useState } from "react"
import { Helmet } from "react-helmet"
import NavbarArea from "../../components/navbar"
import NavBar2 from "../../components/newNavbar/NavBar"
import Footer from "../../components/newNavbar/Footer"
import totalLogo from "../../assets/totalLogo.svg"
import pelezaLogo from "../../assets/pelezaLogo.svg"
import fuelCard from "../../assets/fuelCard.svg"
import gasStation from "../../assets/gasStation.svg"
import pickup from "../../assets/pickUp.svg"
import { FiMapPin } from "react-icons/fi";
import { FaRegCreditCard } from "react-icons/fa6";
import discount from "../../assets/discount.svg"
import Modal from 'react-modal';
import { Input } from "antd"
import axios from "axios"
import toast, { Toaster } from "react-hot-toast";
import { regions, sources } from "./constant"
import rewardsBanner from "../../assets/fuelBanner.png"

const customStyles = {
    content: {
      top: '55%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflowY: "auto",
      maxHeight: "80vh", 
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Black background with 80% opacity
      },
  };

const Rewards = () => {
    const [modalState, setModalState] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [id, setId] = useState("")
    const [whatsApp, setWhatsApp] = useState("")
    const [email, setEmail] = useState("")
    const [vehicleType, setVehicleType] = useState("")
    const [date, setDate] = useState("")
    const [topUpAmt, setTopUpAmt] = useState("")
    const [region, setRegion] = useState("")
    const [source, setSource] = useState("")
    const [loading, setLoading] = useState(false)

    const rewardsHandler = (event) => {
        event.preventDefault()
        setLoading(true)
        const payload = {
            first_name:firstName,
            middle_name:middleName,
            last_name: lastName,
            id,
            whatsApp,
            email,
            vehicle_type:vehicleType,
            date,
            topUpAmt,
            region,
            source,
            card_number:""        }
        axios.post("https://api-dd-staging.prembly.com/common/save-reward-data", payload)
        .then(() => {
            toast.success("Registration Successful. You can now go and collect your card at 24 St. Michael's Road. Waiyaki Way, Westlands")
            setModalState(!modalState)
        }).catch((error) => {
            toast.error("Registration Failed, please contact admin")
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <Fragment>
            <Helmet>
                <title>Prembly- Rewards</title>
                <meta
                name="description"
                content="Identity Verification Solutions for Emerging Markets"
                />
            </Helmet>
            <NavBar2 />
           <center> <img src={rewardsBanner} width={"100%"}/> </center>
            <section className="">
                <div className="d-flex align-items-center justify-content-center items-center pt-5">
                    <img src={totalLogo} alt="Total Logo" className="mx-2" />
                    <span className="text-lg font-bold mx-2">X</span>
                    <img src={pelezaLogo} alt="Peleza Logo" className="mx-2" />
                </div>
                <div className="justify-content-center align-items-center items-center">
                    <center> <h2 className="tw-font-GeneralSans tw-w-[10em] smallText   tw-font-medium  tw-text-center tw-mb-[1rem] tw-text-[3rem]">Fuel Up, Save Big and Drive Happy</h2>
                    <p className="md:tw-w-[23em]"> Total Energies and Peleza Partner Programme for the ultimate rewards</p></center>
                </div>

                <div className="tw-flex tw-mt-5" onClick={() => setModalState(!modalState)}>
                    <span className="tw-p-4 tw-text-white tw-rounded tw-bg-[#184E49] tw-cursor-pointer tw-mx-auto">Start fueling smarter</span>
                </div>
            </section>
            <section className="container tw-mt-[5rem]">
                <div className="tw-flex tw-flex-col md:tw-flex-row">
                    <div className="md:tw-w-1/2 ">
                       <h2 className="tw-font-GeneralSans  tw-w-[12em] md:smallText tw-font-medium tw-mb-[0.5rem] tw-mt-[1rem] md:tw-mb-[1rem] tw-text-[1.5rem] md:tw-text-[2.7rem]"> 🎉 Your Exclusive Driver Perk is Here!</h2>
                    </div>
                    <div className="md:tw-w-1/2 ">
                        <p className="tw-text-[1.5rem] md:tw-w-[30rem]">
                            Get up to KES 2 off every liter of fuel at total energies just for being a part of the Peleza Family!
                        </p>
                        <div className=" tw-mt-5" onClick={() => setModalState(!modalState)}>
                            <span className="tw-p-4 tw-text-white tw-rounded tw-bg-[#184E49] tw-cursor-pointer">Start fueling smarter</span>
                         </div>
                    </div>
                </div>
            </section>

            <section className=" container md:tw-mt-[10rem]">
                <div className="tw-flex tw-flex-col md:tw-flex-row">
                    <div className="md:tw-w-1/2">
                       <h2 className="tw-font-GeneralSans  tw-w-[12em] md:smallText tw-font-medium tw-mb-[0.5rem] tw-mt-[1rem] md:tw-mb-[1rem] tw-text-[1.5rem] md:tw-text-[2.7rem]"> 
                            What is in it for you? Glad you asked
                       </h2>
                    </div>
                    <div className="md:tw-w-1/2 ">
                        <p className="md:tw-w-[20em] tw-text-[1.5rem]">
                            Here are the perks you get from our rewards program!
                        </p>
                    </div>
                </div>
            </section>
       
            <section className="container tw-grid tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-5 md:tw-mt-[2rem]">
            <div>
                <img src={fuelCard} alt="Fuel Card" />
                <p className="tw-font-GeneralSans tw-w-[12em] md:smallText tw-font-medium md:tw-text-[1.3rem] tw-mt-3">Free Total Fuel Card</p>
                <p className="md:tw-w-[14em]">Your golden ticket to mega fuel savings</p>
            </div>
            <div>
                <img src={gasStation} alt="Gas Station" />
                <p className="tw-font-GeneralSans tw-w-[12em] md:smallText tw-font-medium md:tw-text-[1.3rem] tw-mt-3">Every Liter = Savings</p>
                <p className="md:tw-w-[14em]">Enjoy discounts every time you fuel up</p>
            </div>
            <div>
                <img src={pickup} alt="Pickup" />
                <p className="tw-font-GeneralSans tw-w-[12em] md:smallText tw-font-medium md:tw-text-[1.3rem] tw-mt-3">Easy Pick-Up</p>
                <p className="md:tw-w-[14em]">
                Swing by our office at 24 <span className="tw-font-GeneralSans tw-font-medium">Michael's Road of Church Road, Waiyaki Way, Westland</span>
                </p>
            </div>
            </section>
            
            <section className="tw-bg-[#FFF2E6] md:tw-pt-[4rem] tw-p-5 tw-mt-[5rem] md:tw-pb-[5rem]">
                <div className="container tw-grid md:tw-grid-cols-2 ">
                    <div >
                        <h2 className="tw-font-GeneralSans  md:tw-w-[12em] md:smallText tw-font-medium md:tw-text-[3rem] tw-text-[1.3rem] ">Why will you love our rewards program? </h2>
                    </div>
                    <div >
                        <p className="tw-text-[1.2rem]  md:tw-w-[30rem] tw-font-bold ">Here are two reasons why you will love our program</p>
                        <ul style={{ listStyleType: 'disc'  }}>
                            <li>
                                <p className="tw-mt-5 md:tw-text-[1.3rem] md:tw-w-[30rem] tw-font-bold "><span className="tw-font-GeneralSans  md:smallText ">Zero Strings Attached: </span> Claim your card, start saving instantly</p>
                            </li>
                            <li>
                            <p className="tw-mt-5 md:tw-text-[1.3rem] md:tw-w-[30rem] tw-font-bold "><span className="tw-font-GeneralSans  md:smallText ">Limited-Time Only: </span> Offers this sweet do not last forever</p>
                            </li>
                        </ul>
                        
                       
                    </div>
                </div>
            </section>

            <section className="container tw-mt-[3rem]">
               <div className="tw-flex tw-flex-col">
                    <h2 className="tw-font-GeneralSans md:tw-w-[9em] tw-text-center md:smallText tw-font-medium tw-mx-auto md:tw-text-[2.5rem] tw-text-[1.5rem] ">Here is how to get started </h2>
                    <span onClick={() => setModalState(!modalState)} className="tw-px-[4rem] tw-py-4 tw-mt-4 tw-text-white tw-rounded tw-bg-[#184E49] tw-cursor-pointer tw-mx-auto">Register</span>
                </div>
            </section>
            <section className="container tw-grid tw-grid-cols-2 tw-gap-y-5 md:tw-grid-cols-3 tw-mt-10">
            <div>
                <FiMapPin size={25} />
                    <p className="tw-font-GeneralSans tw-w-[12em] md:smallText tw-font-medium md:tw-text-[1.3rem] tw-mt-3">Make Contact</p>   
                    <p className="md:tw-w-[14em]">Visit us at 24 St Michael’s Road</p>
                </div>
                <div>
                <FaRegCreditCard size={25}/>
                     <p className="tw-font-GeneralSans  tw-w-[12em] md:smallText tw-font-medium md:tw-text-[1.3rem] tw-mt-3">Get Card</p>   
                    <p className="md:tw-w-[14em]">Get your <span className="tw-font-bold">FREE </span> Total Card in miutes</p>
                </div>
                <div>
                <img src={discount} />
                     <p className="tw-font-GeneralSans  tw-w-[12em] md:smallText tw-font-medium md:tw-text-[1.3rem] tw-mt-3">Start Saving</p>   
                    <p className="md:tw-w-[14em]">Pump up the savings with KES 2 off every liter!</p>
                </div>
            </section>
                <Modal
                    isOpen={modalState}
                    footer={null}
                    onRequestClose={() => setModalState(!modalState)}
                    style={customStyles} 
                >
                    <div className="md:tw-w-[690px]">
                    <h2 className="tw-font-GeneralSans  md:smallText tw-font-medium tw-text-[1.6rem] tw-mb-5 ">Peleza and Total Energy Rewards </h2> 
                       <form onSubmit={rewardsHandler}>
                        <div className="tw-flex tw-grid tw-gap-4 md:tw-grid-cols-3 tw-grid-cols-1">
                            <div >
                                <label>First Name</label>
                                <input
                                    className="tw-rounded-lg tw-border tw-border-gray-300 tw-h-12 tw-w-full  tw-px-4"
                                    type="text" placeholder="First name"
                                    onChange={(firstName) => setFirstName(firstName.target.value)}
                                    required
                                />
                            </div>
                            <div >
                                <label>Second Name</label>
                                <input
                                    className="tw-rounded-lg tw-border tw-border-gray-300 tw-w-full tw-h-12 tw-px-4"
                                    type="text" placeholder="Middle name"
                                    onChange={(middleName) => setMiddleName(middleName.target.value)}
                                    />
                            </div>
                            <div >
                                <label>Last Name</label>
                                <input
                                    className="tw-rounded-lg tw-border tw-border-gray-300 tw-w-full tw-h-12 tw-px-4"
                                    type="text" placeholder="Last name"
                                    onChange={(lastName) => setLastName(lastName.target.value)}
                                    required
                                    />
                            </div>
                        </div>
                        <div className="tw-mt-5 tw-grid md:tw-grid-cols-2 tw-gap-4 tw-grid-cols-1">
                            <div >
                                <label>ID No.</label>
                                <input
                                    className="tw-rounded-lg tw-border tw-border-gray-300 tw-w-full tw-h-12 tw-px-4"
                                    type="text" placeholder="Enter your ID number"
                                    onChange={(id) => setId(id.target.value)}
                                    required
                                    minLength={8}
                                    />
                            </div>
                            <div>
                                <label>WhatsApp Number</label>
                                <input
                                    className="tw-rounded-lg tw-border tw-border-gray-300 tw-h-12 tw-w-full  tw-px-4"
                                    type="number" placeholder="07XX XXX XXX"
                                    onChange={(whatsApp) => setWhatsApp(whatsApp.target.value)}
                                    required
                                    minLength={10}
                                />
                            </div>
                        </div>
                        <div className="tw-mt-5 tw-flex-col">
                            <label>Email Address</label>
                            <input
                                className="tw-rounded-lg tw-border tw-border-gray-300 tw-h-12 tw-w-[100%] tw-px-4"
                                type="email" placeholder="Enter your email address"
                                onChange={(email) => setEmail(email.target.value)}
                                required
                                />
                        </div>
                       
                        <div className="tw-mt-5 tw-grid md:tw-grid-cols-2 tw-gap-4 tw-grid-cols-1">
                            <div>
                            <p className="tw-mt-5 tw-font-GeneralSans  md:smallText tw-font-medium tw-text-[1rem] tw-mb-2">Vehicle Type</p>
                        <div className="tw-flex">
                            <input type="radio" 
                                name="vehicleType"
                                value="Car" 
                                onChange={(vehicleType) => setVehicleType(vehicleType.target.value)} 
                                checked={vehicleType === "Car"}
                                style={{width:"1.3rem"}}
                            /> 
                            <label className="tw-pl-3 tw-pr-4">Car</label>
                            <input type="radio" className="tw-pl-3" 
                                  name="vehicleType"
                                  value="Bike" 
                                  onChange={(vehicleType) => setVehicleType(vehicleType.target.value)} 
                                  checked={vehicleType === "Bike"}
                                  style={{width:"1.3rem"}}
                            />
                            <label className="tw-pl-3">Bike</label>       
                        </div>
                            </div>
                            <div >
                                <label>Date of Issue</label>
                                <input
                                    className="tw-rounded-lg tw-border tw-border-gray-300 tw-w-full tw-h-12 tw-px-4"
                                    type="date" placeholder="Enter your ID number"
                                    onChange={(date) =>setDate(date.target.value)}
                                    required
                                />
                            </div>
                            {/* <div >
                                <label>First Top Up Amount</label>
                                <input
                                    className="tw-rounded-lg tw-border tw-border-gray-300 tw-w-full tw-h-12 tw-px-4"
                                    type="number" placeholder="Enter your first top up amount"
                                    onChange={(topUpAmt) => setTopUpAmt(topUpAmt.target.value)}
                                    required
                                    />
                            </div> */}
                        </div>

                        <div className="tw-mt-5 tw-grid md:tw-grid-cols-2 tw-gap-4 tw-grid-cols-1">
                            <div>
                                <label>Region</label>
                                <select className="tw-rounded-lg tw-border tw-border-gray-300 tw-h-12  tw-w-full tw-px-4" 
                                    onChange={(region) => setRegion(region.target.value) }>
                                    <option>Select Region</option>
                                    {regions.map((region) => <option>{region}</option>)}
                                    required
                                </select>
                            
                            </div>
                            <div>
                                <label>How did you hear about us?</label>
                                <select className="tw-rounded-lg tw-border tw-border-gray-300 tw-h-12 tw-w-full  tw-px-4" 
                                    onChange={(source) => setSource(source.target.value) }>
                                    <option>Choose Source</option>
                                    {sources.map((source) => <option>{source}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="tw-flex tw-mt-5">
                        <button type="submit" disabled={loading} 
                            className="tw-p-4 tw-text-white tw-rounded-lg tw-bg-[#184E49] tw-cursor-pointer tw-text-center tw-mx-auto tw-w-full">
                                {loading ? "Submitting ..." : "Get Rewards Now"}
                            </button>
                        
                        </div>
                        </form>
                        </div>
                </Modal>
            <Footer/>
            <Toaster />
        </Fragment>
    )
} 

export default Rewards
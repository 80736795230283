export const regions = [
    "Athi River",
    "CBD",
    "Eastlands",
    "Hurlingham",
    "Jogoo Road",
    "Karen",
    "Kiambu",
    "Kilimani",
    "Kikuyu",
    "Limiru",
    "Lang'ata",
    "Mombasa Road",
    "Mlolongo",
    "Nairobi West",
    "Ngong",
    "Ngong Road",
   "Riverside Drive",
   "Rongai",
   "South B/South C",
   "Thika Road",
  "Waiyaki Way",
   "Westlands" 
];

export const sources = [
    "Facebook",
    "SMS From Peleza",
    "Peleza Sales Person",
    "Friend",
    "Website"
]

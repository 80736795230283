import React from "react";
import HomePage from "./pages/Products/home";
import FormsPage from "./pages/forms";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import RadarPage from "./pages/Products/radar";
import PrivacyPage from "./pages/policy";
import IdentityPass from "./pages/Products/identitypass";
import BackgroundCheck from "./pages/Products/BackgroundCheck";
import Tobacco from "./pages/Industries/Tobacco";
import ScrollToTop from "./components/ScrollToTop";
import Compliance from "./pages/Company/compliance";
import Events from "./pages/Resources/Events";
import IdentityForm from "./pages/Products/IdentityForm";
import Press from "./pages/Resources/Press";
import Faqs from "./pages/Resources/Faqs";
import About from "./pages/Company/About";
import Career from "./pages/Company/Career";
import Contact from "./pages/Company/Contact";
import Sector from "./pages/Industries/Sector";
import Crypto from "./pages/Industries/Crypto";
import Insurance from "./pages/Industries/Insurance";
import Management from "./pages/Industries/Management";
import PublicSector from "./pages/Industries/PublicSector";
import Mobility from "./pages/Industries/Mobility";
import DialogueII from "./pages/Resources/DialogueII";
import TermsPolicy from "./pages/terms";
import Protection from "./pages/dataProtection";
// import Meet from "./pages/Company/Meet";

import Cookie from "./components/Cookie";
import Dialogue from "./pages/Resources/Dialogue";
import ErrorPage from "./components/errorPage";
import PartnershipPage from "./pages/Resources/partnership";

// external partners
import Sproutly from "./pages/Partners/External/Sproutly";
import Pulley from "./pages/Partners/External/Pulley";
import Native from "./pages/Partners/External/Native";
import NewHomepage from "./components/newNavbar/NewHomepage";
import AboutPage from "./components/newNavbar/about/AboutPage";
import BoltPage from "./components/newNavbar/about/bolt";
import StoryPage from "./components/newNavbar/StoryPage";
import TrackingPage from "./components/newNavbar/about/Tracking";
import Rewards from "./pages/rewards";

export default function App(props) {
  return (
    <div className=" light-mode">
      <Router>
        <ScrollToTop>
          <Routes>
            {/* <Route exact path="/" element={<HomePage />} /> */}
            <Route exact path="/" element={<NewHomepage />} />
            <Route exact path="/tracking" element={<TrackingPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/story" element={<StoryPage />} />
            <Route exact path="/rewards" element={<Rewards />} />
            {/* external routes */}
            <Route exact path="/partner/sproutly" element={<Sproutly />} />
            <Route exact path="/partner/pulley" element={<Pulley />} />
            <Route exact path="/partner/native-team" element={<Native />} />
            <Route exact path="/bolt" element={<BoltPage />} />
            <Route exact path="/bolt-checks" element={<Navigate to="/bolt" replace />} />
            <Route exact path="/bolt-dl" element={<Navigate to="/bolt" replace />} />


            {/* end of external routes */}
            <Route path="/identityPass" element={<IdentityPass />} />
            <Route exact path="/IdentityRadar" element={<RadarPage />} />
            <Route
              exact
              path="/backgroundCheck"
              element={<BackgroundCheck />}
            />
            <Route exact path="/identityForm" element={<IdentityForm />} />
            <Route exact path="/sector" element={<Sector />} />
            <Route exact path="/tobacco" element={<Tobacco />} />
            <Route exact path="/crypto" element={<Crypto />} />
            <Route exact path="/publicSector" element={<PublicSector />} />
            <Route exact path="/management" element={<Management />} />
            <Route exact path="/insurance" element={<Insurance />} />
            <Route exact path="/mobility" element={<Mobility />} />
            <Route exact path="/event/dialogueII" element={<DialogueII />} />
            <Route exact path="/event/dialogue" element={<Dialogue />} />
            <Route exact path="/partnership" element={<PartnershipPage />} />
            <Route exact path="/cookie" element={<Cookie />} />
            <Route exact path="/press" element={<Press />} />
            <Route exact path="/faqs" element={<Faqs />} />
            <Route exact path="/event" element={<Events />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/contact" element={<Contact />} />
            {/* <Route exact path="/compliance" element={<Compliance />} /> */}
            {/* <Route exact path="/meet" element={<Meet />} /> */}
            <Route exact path="/Policy" element={<PrivacyPage />} />
            <Route exact path="/terms" element={<TermsPolicy />} />
            <Route exact path="/dataProtection" element={<Protection />} />
           
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </div>
  );
}
